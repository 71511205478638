
export function isProduction(){

  // return (true)
  return (process.env.REACT_APP_VERCEL_ENV === "production")
}

export function isLocal(){
  return (process.env.REACT_APP_RUN_ENV === "LOCAL")
}

export function isRemoveManualRule(){
  
  // get the url of the current running environment
  const hostname = window.location.hostname;

  // if the url contains the word remove-manual-rule, then return true
  return (hostname.includes("remove-manual-rule"))
}

